<template>
	<div class="main-screen-settings">
		<div class="top top-minus-margin">
			<img src="/build/icons/settings-arrowback.svg" @click="viewSlide('main-settings-page')" class="back-arrow">
			<h1>{{ $t('list-roles.title') }}</h1>
			<button @click="viewSlide('create_role')" class="button-tbf-blue add"></button>
		</div>
		<section class="list-items-section" v-if="loaded">
			<div class="list-roles-tags">
				<div class="item-role-tag" v-for="role in roles" @click="viewSlide('create_role', role)">
					<div class="title">{{ role.name }}</div>
					<div class="actions dots-edit" v-click-outside="hideDropdown">
						<div class="dropdown edit-item-dropdown">
							<div class="overlay-button" @click.stop="showDropdown(role.id)">
							</div>
							<div class="edit-item-button" :id="'dropdownEditRole'+role.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src="/build/icons/edit-dots.svg">
							</div>
							<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenuRole'+ role.id " :aria-labelledby="'dropdownEditRole'+role.id">
								<div class="dropdown-item" @click.stop="viewSlide('create_role', role)">{{ $t('list-roles.edit') }}</div>
								<div class="dropdown-item" @click.stop="deleteItem('confirm_delete', role.id)" v-if="role.name != 'admin' && role.name != 'manager' && role.name != 'employee' && role.name != 'observer'">{{ $t('list-roles.delete') }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<div class="submodal-container" v-if="subModalShow">
			<confirm-delete v-if="show_subm_modal.confirm_delete" nameItem="rol" @cancel_delete="closeDelete('confirm_delete')" @delete_item="confirmDelete('confirm_delete')"></confirm-delete>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ConfirmDelete from "../SubModals/ConfirmDelete"

	export default {
		data() {
			return {
				loaded: false,
				activeSlide: 'info_key_result',
				roles: {},
				subModalShow: false,
                show_subm_modal: {
                	confirm_delete: false
                },
                deleteItemId: '',
                hoverRole: ''
			};
		},
		components: {
			ConfirmDelete
		},
		async mounted() {
			await this.getRoles()

            this.$root.$on("reload_modal_key_result", () => {
				this.getRoles()
            });
		},
		methods: {
			async getRoles(){
				await axios.get('/' + this.$auth.user().instance_id + '/tags')
				.then(({data}) => {
					this.roles = data.data
				})
				.then(() => {
					this.loaded = true
				})
			},
			changeActiveSlide(value){
				if(value == 'info_key_result_update'){
					this.activeSlide = 'info_key_result'
					this.getRoles()
				}else{
					this.activeSlide = value
				}
			},
			viewSlide(slide, modelRole){
				this.$emit('change_slide', slide, modelRole)
			},
			deleteItem(type, itemId){
				this.hideDropdown()
				this.deleteItemId = itemId
				this.subModalShow = true
				this.show_subm_modal[type] = true
				setTimeout(() => {
                	$('.submodal-container').addClass('active');
                	setTimeout(() => {
                		$('#submodal_' + type).addClass('active');
					}, 200);
                }, 0);
			},
			closeDelete(type){
				this.deleteItemId = ''
				$("#submodal_" + type).removeClass("active")
				setTimeout(() => {
					$('.submodal-container').removeClass('active')
					setTimeout(() => {
						this.subModalShow = false;
						this.show_subm_modal[type] = false
					}, 200);
				}, 200);
			},
			confirmDelete(type){
				axios.delete('/roles/' + this.deleteItemId)
				.then(() => {
					this.deleteItemId = ''
					$("#submodal_" + type).removeClass("active")
					this.getRoles()
					setTimeout(() => {
						$('.submodal-container').removeClass('active')
						setTimeout(() => {
							this.subModalShow = false;
							this.show_subm_modal[type] = false
						}, 200);
					}, 200);
				})
			},
			showDropdown(itemId){
				if(itemId == this.hoverRole){
					$('#dropdownEditRole' + this.hoverRole).dropdown('toggle');
					setTimeout(() => {
						this.hoverRole = ''
					}, 0)
				}else{
					this.hoverRole = itemId
					setTimeout(() => {
						$('#dropdownEditRole' + this.hoverRole).dropdown('toggle');
					}, 0)
				}

			},
			hideDropdown(){
				if(this.hoverRole != ''){
					$('#dropdownEditRole' + this.hoverRole).dropdown('toggle');
					
					setTimeout(() => {
						this.hoverRole = ''
					}, 0)
				}
			}
		}
	};
</script>